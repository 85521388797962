import * as React from "react"
import * as TextStyles from "./Text.module.css"

function Text({ children }) {
    return (
        <div className={TextStyles.container}>
            {children}
        </div>
    )
}

export default Text