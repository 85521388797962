import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout/layout"
import TxtImg from "../../components/TxtImg/TxtImg"
import Text from "../../components/Text/Text"
import Console from "../../components/Console/Console"
import Accordion from "../../components/Accordion/Accordion"
import Button from "../../components/Button/Button"

function VisTherapy({ data }) {
    return (
        <Layout>
            <Helmet>
                <meta name="description" content="" />
            </Helmet>
            <section style={{paddingTop: 'var(--space-5)'}}>
                <TxtImg
                    imgFirst={true}
                    split="half"
                    imgSrc={data.eyemindLg}
                    imgAlt="Dr. Christian French, Consulting Optometrist vision therapy logo"
                    removeShadow={true}
                >
                    <h2>Vision Therapy</h2>
                    <p>Although we often use the words 'sight' and 'vision' interchangeably, there is a difference. Sight is our ability to see something, whereas vision involves input from our brain to make sense of what we are looking at and decide on our response to it.</p>
                    <p>The traditional eye examination principally focuses on sight; what is the smallest letter we can see and what lenses are required to make it clearer? Sometimes however, patients experience visual problems which are not the result of a sight problem, but rather an issue with vision and visual processing. In these cases, vision therapy may be suitable.</p>
                </TxtImg>
            </section>
            <section style={{paddingTop: 0}}>
                <Text>
                    <h3>Conditions</h3>
                    <p>There is a range of conditions which can be associated with vision therapy. In addition to difficulties focussing at near, eye co-ordination and other aspects of vision, conditions can include visual stress (difficulties with reading, artificial lighting and migraine) and visual snow syndrome (permanent static in vision, light sensitivity and after-images).</p>
                    <p>Learn more about these conditions:</p>
                    <Accordion heading="Visual Stress" borderTop={true}>
                        <h5>Visual stress: tints and reading difficulties</h5>
                        <p>One of the most common avenues in vision therapy is patients who are struggling with reading and experiencing visual stress. Visual stress is often associated with dyslexia and other specific learning difficulties, but it is extremely important to remember that optometrists cannot diagnose dyslexia; support is purely aimed at helping manage the visual stress. This is often in the form of a dedicated coloured filter; either an overlay, clip-on or a tint built into a pair of spectacles.</p>
                        <h5>What is visual stress?</h5>
                        <p>Everyone experiences visual stress; it is a symptom resulting from our visual system being exposed to too much visual information. Think about the sensation you experience when looking at a finely striped shirt. You may see blurring, colours, shimmering or it may just feel very uncomfortable to look at. This is normal and is because our vision isn't ‘designed’ to look at such patterns.</p>
                        <p>Some people experience visual stress with more everyday patterns; text on a page, artificial lighting, supermarkets etc. This can be very debilitating and can impact on everyday life. For children at school, it can affect their attention and behaviour in class, or cause them to fall behind in some studies.</p>
                        <h5>Who can suffer from visual stress?</h5>
                        <p>The short answer is: anyone. We all experience visual stress to some degree but it is typically only with very extreme stimuli. Those who suffer with visual stress will get the same symptoms occurring with more everyday patterns. This has been associated with people who also have dyslexia and other specific learning difficulties, as well as migraine sufferers and people who have suffered a stroke. Quite often, sufferers will experience headaches, discomfort in artificial lighting, and difficulty or a dislike of reading.</p>
                        <h5>What can be done to help?</h5>
                        <p>It is always important to exclude any other contributing factor which may be giving similar symptoms of visual stress. This is because there is no absolute test for visual stress and we must therefore rule out all other potential causes. Typically, visual stress symptoms are softened by particular-coloured filters. This colour is as individual to each person as a spectacle prescription, ranging in colour and intensity. These lenses can be worn full-time or only in situations where symptoms are at their worst. Determining and refining this colour prescription forms part of the visual stress assessment.</p>
                    </Accordion>
                    <Accordion heading="Visual Snow Syndrome (VSS)">
                        <p>Yes, Visual Snow Syndrome is a relatively recently recognised condition. As the name suggests, the principal feature of VSS is a constant static-like 'fuzz' present across the whole field of vision (like an old TV that isn’t tuned in), but to diagnose the syndrome a number of other symptoms need to be present as well.</p>
                        <p>These typically include increased light sensitivity (photophobia) and persistent after-images and trailing of lights (palinopsia) in addition to non-visual symptoms such as migraine, tinnitus, muscle cramps, numbness or tingling and even depersonalisation (out-of-body sensations).</p>
                        <p>Visual Snow Syndrome cannot currently be cured, but there are a number of approaches which aim help to reduce the symptoms to a more tolerable level.</p>
                    </Accordion>
                    <h3>Am I suitable for vision therapy?</h3>
                    <p>If you are having symptoms relating to your eyesight or vision which do not seem to have been helped with conventional spectacles or contact lenses, it is worth getting in touch to discuss your situation. We can talk through your symptoms, your case history and your goals to decide what the best course of action is. Everyone is different, and I find it is hugely important to talk with you prior to an appointment to get the best picture of your symptoms and your present situation. This way, I can better advise you on a likely timeline.</p>
                    <h3>What to expect from a vision therapy appointment</h3>
                    <p>This involves an initial 2-hour assessment with a thorough overview of your eyes and vision. The assessment will explore the symptoms that you are experiencing and apply a therapeutic-based approach to explore what is happening with your vision and what can be done to improve visual comfort. Often this can involve incorporating other senses and skills such as motor and balance.</p>
                    <p>Every person is different, so these consultations are not prescriptive, and an individual, tailored programme of tests and exercises will be compiled to explore and understand your vision.</p>
                    <p>Following your appointment, you will be given a detailed medical report outlining the findings and suggested management, including any exercises you may require which can be shared with other healthcare workers and employers.</p>
                    <Console variant="center">
                        <p style={{fontSize: "var(--type-xxs)"}}><strong style={{textDecoration: "underline"}}>Disclaimer:</strong> Vision therapy aims to help lessen or alleviate visual symptoms. It is not designed to diagnose conditions such as dyslexia, nor does it aim to ‘cure’ them. By booking an appointment you will be accepting that outcomes cannot be guaranteed and that referral to other specialisms may be necessary to provide all-round support.</p>
                    </Console>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--neutral-900)'}}>
                <TxtImg
                    imgFirst={false}
                    split="half"
                    imgSrc={data.eyemindSm}
                    imgAlt="Dr. Christian French, Consulting Optometrist vision therapy logo"
                    removeShadow={true}
                >
                    <h2>Make an appointment</h2>
                    <p>In order to understand your symptoms and aims for vision therapy, I offer a free 15-minute virtual consultation to discuss the process and give an indication regarding fees.</p>
                    <Button to="/contact/" external={false} type="primarySolid" margin="var(--space-5) 0 0 0">Get in touch</Button>
                </TxtImg>
            </section>
        </Layout>
    )
}

export const query = graphql`
query {
    eyemindLg: file(relativePath: {eq: "CFCO_EyeMind_colour.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: WEBP
            )
        }
    }
    eyemindSm: file(relativePath: {eq: "CFCO_EyeMind_colour.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: WEBP
            )
        }
    }
}
`

export default VisTherapy