import * as React from "react"
import * as ConsoleStyles from "./Console.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

function Console({ variant, children }) {
    return (
        <div className={`${ConsoleStyles[variant]} ${ConsoleStyles.container}`} >
            { variant === "info" && <FontAwesomeIcon className={ConsoleStyles.icon} icon={faInfoCircle} style={{fontSize: 'var(--type-l)'}} />}
            {children}
        </div>
    )
}

export default Console