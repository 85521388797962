import React, { useState } from "react"
import * as AccordionStyles from "./Accordion.module.css"

function Accordion({ heading, borderTop, children }) {
    const [showBody, setShowBody] = useState(false)

    return (
        <div className={AccordionStyles.container} style={{borderTop: borderTop && '1px solid var(--neutral-900)'}}>
            <div className={AccordionStyles.heading}>
                <h3>{heading}</h3>
                <span className={AccordionStyles.toggle} onClick={() => setShowBody(showBody ? false : true)}>{showBody ? `–` : `+`}</span>
            </div>
            {
                showBody && (
                    <div className={AccordionStyles.body}>
                        {children}
                    </div>
                )
            }
            
        </div>
    )
}

export default Accordion